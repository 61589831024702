export function WattLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 300"
      xmlSpace="preserve"
      aria-labelledby="titleId descId"
      {...props}
    >
      <title id="titleId">Logo of Watt.cuk</title>
      <desc id="descId">
        A graphic representation of the Watt.co.uk logo, consisting of a
        stylized W in green.
      </desc>
      <path
        fill="#92BC21"
        d="M122.2 233.5h-.3c-3.5-.1-6.7-2.2-8.2-5.4l-54-115.5c-2.2-4.7-.2-10.3 4.5-12.4 4.7-2.2 10.3-.2 12.4 4.5l46.2 98.9 16.5-29.7-11.4-20.9c-4.2-7.5-18-33.2-18-46.3 0-21.7 17.7-39.4 39.4-39.4 21.8 0 39.4 17.7 39.4 39.4 0 12.4-12.5 38.3-16.3 45.9-.1.1-.1.2-.2.4l-11.6 20.9 16.3 29.4 46.2-98.9c2.2-4.7 7.7-6.7 12.4-4.5s6.7 7.8 4.5 12.4l-54 115.5c-1.5 3.2-4.7 5.3-8.2 5.4-3.5.1-6.8-1.7-8.5-4.8l-19.5-35.2-19.7 35.5c-1.3 3-4.5 4.8-7.9 4.8zM149.4 86c-11.4 0-20.7 9.3-20.7 20.7 0 5.8 7.9 23.5 15.6 37.2l5.9 10.6 5.8-10.4c7-14 14.2-31.8 14.2-37.4-.1-11.4-9.4-20.7-20.8-20.7z"
      />
      <path
        fill="currentColor"
        d="M150 300C67.3 300 0 232.7 0 150S67.3 0 150 0s150 67.3 150 150-67.3 150-150 150zm0-280.9C77.8 19.1 19.1 77.8 19.1 150S77.8 280.9 150 280.9 280.9 222.2 280.9 150 222.2 19.1 150 19.1z"
      />
    </svg>
  );
}
